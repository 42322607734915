import CustomBreadcrumbs from "@components/CustomBreadcrumbs/CustomBreadcrumbs";
import MENU_ITEMS from "@constants/menuItems";
import AppLogo from "@icons/AppLogo";
import { createTheme } from "@mui/material";
import { Account, AccountPreview, DashboardLayout, PageContainer, useSession } from "@toolpad/core";
import { AppProvider } from "@toolpad/core/react-router-dom";
import { Outlet } from "react-router-dom";
import "./dashboard.css";
import { useAuth } from "@contexts/AuthContext";
import { APP_VERSION } from "@constants/appConstants";
import { useEffect, useState } from "react";
import { LogoutIcon } from "@icons/DashboardIcons";
import { UploadStatusDialog } from "@components/index";
import { isSuperAdmin } from "@utils/accessControl";

const CustomAccountPreview = ({open, handleClick, ...props}) => {
    const { user } = useAuth();
    
    return (
        <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
            <div className="dashboard-box-header-user">
                <div className="dahsboard-box-header-user-name">Hi, {user?.firstname} {user?.lastname}</div>
                <div className="dahsboard-box-header-user-role">{user?.role}</div>
            </div>
            <AccountPreview
                open={open}
                handleClick={handleClick}
                variant="condensed"
            />
        </div>
)}

const CustomAccount = ()=>{
    return (
        <Account
            slots={{
                preview : CustomAccountPreview
            }}       
        />
)}

const SideBarFooter = ({mini}) => {
    return (
        <div className="sidebar-footer">
            {
                mini ? `V${APP_VERSION}` : `Version-${APP_VERSION}`
            }
        </div>
    )
}

const Dashboard = ()=> {

    const { user, session, logout } = useAuth();

    // Upload Status Dialog
    const [uploadDialog, setUploadDialog] = useState('');

    window.onbeforeunload = (event)=>{
        event.preventDefault(); // prevent un-intentional reload while uploading
    }

    const customTheme = createTheme({
        palette : {
          primary : {
            main : "#EA723C",
          },
          background: {
            default:"#F9F9F9",
          },
          text : {
            primary : "#1E1E1E",
            disabled : "#EBEBEB"
          }
        },
        typography : {
            fontFamily : "Montserrat"
        },
        components : {
            MuiListItem : {
                styleOverrides : {
                    root : {
                        margin:"0.5rem 0rem",
                    }
                }
            }
        },
        cssVariables: {
          // colorSchemeSelector: 'data-toolpad-color-scheme',
        },
        colorSchemes: {
          // light: {
          //   palette: {
          //     background: {
          //       default: '#F9F9FE',
          //       paper: '#EEEEF9',
          //     },
          //   },
          // },
          // dark: {
          //   palette: {
          //     background: {
          //       default: '#2A4364',
          //       paper: '#112E4D',
          //     },
          //   },
          // },
        },
        // breakpoints: {
        //   values: {
        //     xs: 0,
        //     sm: 600,
        //     md: 600,
        //     lg: 1200,
        //     xl: 1536,
        //   },
        // },
      });
 
    return (
        <AppProvider
            navigation={MENU_ITEMS.filter(item => item.autorization ? isSuperAdmin(user) : true)}
            theme={customTheme}
            branding={{
                // logo: <AppLogo style={{padding:"4px 0px"}} />,
                // title: " ",
                // logo: <img src="logo512.png" alt="logo" />,
                // title: "Scan2Hire",
                homeUrl: '/',
            }}
            session={session}
            authentication={{
                signIn : ()=>{},
                signOut: logout
            }}
        >
            <div className="upload-status-container-wrapper">
                <UploadStatusDialog 
                    title={'resume'}
                    documentType={"resumes"} 
                    active={(uploadDialog === 'resume')} 
                    onExpand={()=>{setUploadDialog('resume')}} 
                    onClose={()=>{
                        setUploadDialog("");
                    }}
                />
                <UploadStatusDialog 
                    title={'job description'}
                    documentType={'job-descriptions'} 
                    active={uploadDialog === 'job description'} 
                    onExpand={()=>{setUploadDialog('job description')}} 
                    onClose={()=>{
                        setUploadDialog("");
                    }}
                />
            </div>
            
            <DashboardLayout
                branding={{
                    logo: <img src="logo512.png" alt="logo" />,
                    // title: "Scan2Hire"
                    title : <div className="app-logo">
                                <div className="app-logo-title">scan2hire</div>
                                <div className="app-logo-subtitle">Hire. Accurate.</div>
                            </div>
                }}
                sidebarExpandedWidth={"18rem"}
                slots={{
                    sidebarFooter : SideBarFooter,
                    // toolbarAccount : CustomAccount
                    // toolbarAccount : ()=><Account slots={{preview: (props)=>"Hello World"}}></Account>,
                }}
                sx={{
                    ".MuiListItemButton-root" : {
                        transition : "background-color ease-in-out 150ms",
                        ".MuiTypography-root" : {
                            transition : "color ease-in-out 150ms",
                            fontWeight : 500,
                        },
                        "& .MuiListItemIcon-root" : {
                            background : "white",
                            minWidth : "unset",
                            marginLeft : "-0.225rem",
                            padding : "0.4rem",
                            display : "flex",
                            // display: "flex",
                            alignItems : "center",
                            justifyContent : "center",
                            borderRadius : "4px",
                            marginRight : "1.2rem",
                            // height : "100%",
                            // background : "white"
                        },
                    },
                    ".MuiListItemButton-root.Mui-selected" : {
                        background : "#EA723C",
                        "& .MuiTypography-root" : {
                            color : "white",
                        },
                        "& svg *" : {
                            stroke : "black"
                        },
                        "&:hover" : {
                            background : "#EA723C"
                        }
                    }
                }}
                slotProps={{
                    toolbarAccount : {
                        slots : {
                            preview : CustomAccountPreview,
                        },
                        localeText : {
                            signOutLabel : "Logout"
                        },
                        slotProps : {
                            popoverContent : {
                                sx : {
                                    ".MuiAvatar-root" : {
                                        display : "none"
                                    }
                                }
                            },
                            signOutButton : {
                                startIcon : <LogoutIcon color="#EA723C" />
                            }
                        }
                    }
                }}
                
                // slotProps={{
                //     toolbarAccount : {
                //         slotProps : {
                //             preview : {
                //                 variant : "expanded"
                //             }
                //         }
                //     }
                // }}
            >
                <PageContainer
                    slots={{
                        header : CustomBreadcrumbs,
                    }}
                    component={'div'}
                    className='dashboard-box'
                    style={{
                        flex : 1,
                        maxWidth : "unset"
                    }}
                >
                    <Outlet />
                </PageContainer>
            </DashboardLayout>
        </AppProvider>
)}

export default Dashboard;