import { CustomAutoComplete, CustomTextField } from "@components/CustomMUIInputs";
import { useDocuments } from "@contexts/DocumentsContext";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { Replay } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, List, ListItem, Radio, RadioGroup, Slider, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const RNAFilterDrawer = ({open, onClose, onApplyFilters=()=>{}, defaultScoreFilter}) => {
    const [scoreFilter, setScoreFilter] = useState(defaultScoreFilter || [0, 100]);
    
    useEffect(()=>{
        setScoreFilter(defaultScoreFilter || [0, 100]);
    }, [defaultScoreFilter]);

    const handleChangeScoreFilter = (newValue, activeThumb) => {
        const minDistance = 10;
        if (!Array.isArray(newValue)) {
            return;
        }
        if(newValue[0] < 0 || newValue[1] > 100) return;
        if (activeThumb === 0) {
            setScoreFilter(prev=>[Math.min(newValue[0], prev[1] - minDistance), prev[1]]);
        } else {
            setScoreFilter(prev=>[prev[0], Math.max(0, newValue[1], prev[0] + minDistance)]);
        }
    };

    const handleApplyFilters = () => {
        onApplyFilters({
            scoreFilter : {
                min: scoreFilter[0], max: scoreFilter[1]
            }
        });
    }

    const handleResetFilters = () => {
        setScoreFilter([0, 100]);
    }

    return (
        <Drawer anchor="right" open={open} onClose={onClose} sx={{zIndex : 1250}}>
            <Box 
                width={"20rem"} 
                height={"100%"} 
                padding={"0rem 1.5rem 1rem"} 
                maxWidth={"65vw"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
            >
                <List>
                    <ListItem style={{display:"flex", justifyContent:"space-between"}}>
                        <div 
                            style={{
                               fontSize:"1.3rem",
                               fontWeight:"700" 
                            }}
                        >Filters</div>
                        <div>
                            <IconButton onClick={handleResetFilters}>
                                <Replay color="gray" width="1.5rem" height="1.5rem" />
                            </IconButton>
                        </div>
                    </ListItem>
                    <Divider />
                    <ListItem style={{margin:"1rem 0rem", flexDirection:"column", alignItems:"flex-start", width:"100%"}}>
                        <label
                            style={{
                                fontSize:"1.1rem",
                                fontWeight:"600",
                                color:"black",
                                margin:"0.5rem 0rem"
                            }}
                        >
                            Score
                        </label>
                        
                        <div style={{width:"100%"}}>
                            <Slider 
                                getAriaLabel={() => 'Temperature range'}
                                value={scoreFilter} 
                                onChange={(event, newVal, activeThumb)=>handleChangeScoreFilter(newVal, activeThumb)} 
                                valueLabelDisplay="auto"
                            />
                            <div style={{display:"flex"}}>
                                <CustomTextField 
                                    label="Min"
                                    inputType="number"
                                    value={scoreFilter[0]}
                                    onChange={(event)=>handleChangeScoreFilter([Number.parseInt(event.target.value), scoreFilter[1]], 0)}
                                    slotProps={{
                                        htmlInput:{
                                            min:0,
                                            max:100
                                        }
                                    }}
                                    sx={{
                                        height:"2.5rem"
                                    }}
                                />
                                <div style={{margin:"1rem"}}></div>
                                <CustomTextField 
                                    label="Max"
                                    inputType="number"
                                    value={scoreFilter[1]}
                                    onChange={(event)=>handleChangeScoreFilter([scoreFilter[0], Number.parseInt(event.target.value)], 1)}
                                    slotProps={{
                                        htmlInput:{
                                            min:0,
                                            max:100
                                        }
                                    }}
                                    sx={{
                                        height:"2.5rem"
                                    }}
                                />
                            </div>
                        </div>
                    </ListItem>
                </List>
                <div
                    style={{
                        display:"flex"
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            color:"black",
                            borderColor:"gray",
                            "&:hover" : {
                                borderColor:"black"
                            }
                        }}
                        style={{
                            background:"transparent",
                            textTransform:"none",
                            width:"100%",
                            height:"2.5rem",
                            margin:"0.5rem",
                            borderRadius:"12px",
                            fontWeight:"600",
                        }} 
                    >Cancel</Button>
                    <Button
                        onClick={handleApplyFilters}
                        variant="contained"
                        style={{
                            background:"#EA723C",
                            textTransform:"none",
                            width:"100%",
                            height:"2.5rem",
                            margin:"0.5rem",
                            borderRadius:"12px",
                            fontWeight:"600",
                        }} 
                    >Apply</Button>
                </div>
            </Box>
        </Drawer>
    )
}

export default RNAFilterDrawer;