const NameRenderer = ({data:user})=>{
    return (
        <div
            style={{
                fontSize : "1rem",
                padding:"0",
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red"
            }}
        >
            <div
                style={{
                    width: "inherit"
                }}
            >
                <div style={{height:"25px", lineHeight:"1.5rem", margin:"0rem", fontWeight:"500", overflowX:"clip", textOverflow:"ellipsis"}}>{user.firstname} {user.lastname}</div>
                <div
                    style={{
                        color:"gray",
                        overflowX:"clip",
                        textOverflow:"ellipsis",
                        lineHeight: "1.5rem",
                        margin: "0rem"
                    }}
                >{user.username}</div>
            </div>
        </div>
    )
}

export default NameRenderer;