const RESUME_FEILDS = {
    // 'filename' : { text : 'Filename', type : "string" } ,
    'candidate_name' : {text : "Name", type : "string"},
    "email" : {text : "Email", type : "string"} ,
    "mobile_number" : {text : "Mobile Number", type : "string"},
    "hard_skills" : {text : "Skills", type : "chip-array"},
    'work_experience' : {text : "Work Experience", type : "timeline"},
    'education' : {text : "Education", type : "timeline"}
}

const JD_FIELDS = {
    // 'filename' : { text : 'Filename', type : "string" },
    'job_title' : {text : 'Job Title', type : "string"},
    'company_name' : {text : "Company Name", type : "string"},
    'location' : {text : "Location", type : "string"},
    'required_hard_skills' : {text : "Required Hard Skills", type : "chip-array"},
    'required_soft_skills' : {text : "Required Soft Skills", type : "chip-array"},
    'required_experience_in_years' : {text : "Required Experience (in Years)", type : "string"}
}

export { RESUME_FEILDS, JD_FIELDS };