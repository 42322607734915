import axios from "axios";
import { extractErrorMessage } from "@utils/errorHandler";

const createAxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL : baseURL,
        headers : {
            "Content-Type" : "application/json"
        }
    })

    // To handle the error message from response
    instance.interceptors.response.use(
        response => response,
        error => {
            const errorMessage = extractErrorMessage(error);
            error.message = errorMessage;
            return Promise.reject(error);
        }
    )

    return instance;
};

export const authAPI = createAxiosInstance("/api/auth");
export const usersAPI = createAxiosInstance("/api/users");
export const ticketsAPI = createAxiosInstance("/api/tickets");
export const webCareersAPI = createAxiosInstance("/api/web-careers");
export const documentsAPI = createAxiosInstance("/api/documents");