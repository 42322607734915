import { CustomHeadRow, CustomRow } from "@components/CustomTableComponents";
import useResponsiveView from "@hooks/useResponsiveView";
import { RankAndAnalyzeIcon } from "@icons/DashboardIcons";
import { CrossCircleIcon, YesIcon, CrossIcon } from "@icons/DialogsIcons";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./analyze-renderer.css";

const NotDetected = ({text}) => {
    return (
        <span style={{
            color: 'lightgray'
        }}>
            {text ? text : 'Not Detected'}
        </span>
    )
}

const AnalyzeHeader = ({item}) => {
    return (
    <div className="analyze-header">
        <h3>{item.name}</h3>
        <div className="analyze-header-scores">
            <div>{(100 * item.score).toFixed(2)}</div>
            <sub>(Weight: {(100 * item.weight).toFixed(2)}%)</sub>
        </div>
    </div>
)}

const AnalyzeRenderer = (params)=>{
    const isMobile = useResponsiveView();
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);
    const handleClose = ()=>{
        setIsOpen(false);   
    }
    
    return (
        error ? null :
        <>
            <Dialog fullWidth open={isOpen} onClose={handleClose} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white"},
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black",
                        position:"relative"
                    }}
                >
                    <div>Analyze</div>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                        <CrossCircleIcon color="black" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        height:"50svh",
                        padding:isMobile ? 0 : undefined
                    }}
                >
                    {
                        params.data.analyze_details.map((item, idx)=>{
                            switch(item.name){
                                case "Similarity Score":
                                    return (
                                        <React.Fragment key={idx}>
                                            <AnalyzeHeader item={item} />
                                            <Divider sx={{width:"100%", background:"#0000001F"}} />
                                        </React.Fragment>
                                    )
                                case "Experience":
                                    return (
                                        <React.Fragment key={idx}>
                                            <AnalyzeHeader item={item} />
                                            <CustomHeadRow key={"h3"+idx} column1={"Required Experience"} column2={"Candidate Experience"}/>
                                            <div
                                                style={{
                                                    width:"calc(100% - 1rem)",
                                                    borderRadius:"16px",
                                                    border:"1px solid #1E1E1E8B",
                                                    marginBottom:"1rem",
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    alignItems:"center"
                                                }}
                                            >
                                                <CustomRow 
                                                    column1={item.required ? `${item.required} Year(s)` : <NotDetected />}
                                                    column2={item.candidate ? `${item.candidate} Year(s)` : <NotDetected />}
                                                    borderFlag={false}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )

                                case "Hard Skills":
                                case "Soft Skills":
                                    return (
                                        <React.Fragment key={idx}>
                                            <AnalyzeHeader item={item} />
                                            <CustomHeadRow key={"h3"+idx} column1={`Required ${item.name}`} column2={"Found in Resume"}/>
                                            <div
                                                style={{
                                                    width:"calc(100% - 1rem)",
                                                    borderRadius:"16px",
                                                    border:"1px solid #1E1E1E8B",
                                                    marginBottom:"1rem",
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    alignItems:"center"
                                                }}
                                            >
                                                {
                                                    !item?.keywords_presence?.length ? <div align="center" style={{fontSize:"1rem", padding:"1rem", fontFamily:"Montserrat"}}><NotDetected text={"No Skills detected in Job description"} /></div> :
                                                    item.keywords_presence.map(([skill, found], index)=>(
                                                        <CustomRow 
                                                            key={index}
                                                            column1={skill}
                                                            column2={found ? <YesIcon color="#00B350" /> : <CrossIcon color="#FF5E62" />}
                                                            borderFlag={index !== (item.keywords_presence.length - 1)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            {
                                                (idx !== params.data.analyze_details.length - 1) &&
                                                <Divider sx={{width:"100%", background:"#0000001F"}} />
                                            }
                                        </React.Fragment>
                                    )
                                
                                default:
                                    return "";
                            }
                        })
                    }
                    {/* <CustomHeadRow key={1} column1={"Required Experience"} column2={"Candidate Experience"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        <CustomRow 
                            column1={requiredExperience ? requiredExperience + ' Year(s)' : <NotDetected />} 
                            column2={candidateExperience ? candidateExperience + ' Year(s)' : <NotDetected />}
                            borderFlag={false}
                        />
                    </div>
                    
                    <CustomHeadRow key={2} column1={"Required Skills"} column2={"Found in Resume"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        {
                            !skillsFound ? <div align="center" style={{fontSize:"1rem", padding:"1rem", fontFamily:"Montserrat"}}><NotDetected text={"No Skills detected in Job description"} /></div> :
                            skillsFound.map(([skill, found], index)=>(
                                <CustomRow 
                                    key={index}
                                    column1={skill}
                                    column2={found ? <YesIcon color="#00B350" /> : <CrossIcon color="#FF5E62" />}
                                    borderFlag={index !== (skillsFound.length - 1)}
                                />
                            ))
                        }
                    </div> */}
                </DialogContent>
            </Dialog>

            <IconButton onClick={()=>setIsOpen(true)}>
                <RankAndAnalyzeIcon />
            </IconButton>
        </>
    )
}

export default AnalyzeRenderer;