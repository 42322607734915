import useResponsiveView from "@hooks/useResponsiveView";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { ArrowBack, Scale, Score, Settings } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Slider } from "@mui/material";
import { PieChart, ResponsiveChartContainer } from "@mui/x-charts";
import { useEffect, useState } from "react";
import "./advanced-weightage-score-dialog.css";
import { CustomTextField } from "@components/CustomMUIInputs";
import { DeleteIcon, PlusIcon } from "@icons/ActionsIcons";

const AdvancedWeightgeScoreDialog = ({open, document, onApplyWeights=()=>{}, onClose=()=>{}})=>{
    
    const [weights, setWeights] = useState(()=>{
        if(!document) return [];
        const sum = document.weights.reduce((prev, item)=>(prev + item.priority), 0)
        return document.weights.map((item)=>({label : item.name, value : 100 * item.priority/sum}))
    });
    const [priorities, setPriorities] = useState(document?.weights || []);
    const [subWeightsOpen, setSubWeightsOpen] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(()=>{
        setIsUpdating(false);
    }, [open])
    
    const handleOnChange = (event) => {
        const {name, value} = event.target;
        if(subWeightsOpen){
            console.log(name, subWeightsOpen)
            setPriorities(prev=>prev.map(item=>{
                console.log(item)
                return item.name === subWeightsOpen ? {...item, 
                    childrens: item.childrens.map(child=>{
                        return child.name === name ? {...child, priority:value} : child
                    })
                } : item
            }))
        }else{
            setPriorities(prev=>prev.map(item=>{
                return item.name === name ? {...item, priority:value} : item
            }))
        }
    }

    useEffect(()=>{
        if(document){
            setPriorities(document.weights);
        }
    }, [document])

    useEffect(()=>{
        setWeights(()=>{
            const tempPriorities = subWeightsOpen ? priorities.find(item => item.name === subWeightsOpen).childrens || [] : priorities;
            const sum = tempPriorities.reduce((prev, item)=>(prev + item.priority), 0)
            return tempPriorities.map((item)=>({label : item.name, value : 100 * item.priority/sum}))
        })
    }, [subWeightsOpen, priorities])

    const isMobile = useResponsiveView();
    const handleClose = ()=>{
        onClose();
        setSubWeightsOpen(null);
    }

    const handleOpenSubWeights = (item) => {
        setSubWeightsOpen(item.name);
    }

    const handleCloseSubWeights = ()=>{
        setSubWeightsOpen(null);
    }

    const [newSubKeyword, setNewSubKeyword] = useState("");
    const handleAddNewKeyword = () => {
        if(!subWeightsOpen || !newSubKeyword) return;

        setPriorities(prev=>prev.map(item=>{
            return item.name === subWeightsOpen ? 
                {...item, childrens:[...item.childrens, {name:newSubKeyword, priority:1}]}
                :
                item
        }))
        setNewSubKeyword("");
    }

    const handleRemoveKeyword = (selectedItem) => {
        if(!subWeightsOpen) return;
        setPriorities(prev=>prev.map(item=>{
            return item.name === subWeightsOpen ? 
                {...item, childrens:item.childrens.filter(child=>child!==selectedItem)}
                :
                item
        }))
    }

    const handleResetSubKeywords = () => {
        setPriorities(prev=>prev.map(item=>{
            return item.name === subWeightsOpen ?
            {...item, childrens: document.weights.find(p => p.name === subWeightsOpen).childrens || []}
            :
            item
        }))
    }

    return (
        <Dialog fullWidth open={open || false} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:isMobile ? "0" : "1rem", height:"80%", maxHeight:"550px", background:"white"},
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    position:"relative"
                }}
            >
                {
                    subWeightsOpen && 
                    <div style={{position:"absolute", top:"1rem", left:"1rem", cursor:"pointer"}} onClick={handleCloseSubWeights}>
                        <ArrowBack color="#1E1E1E" height="25" width="25" />
                    </div>
                }
                <div
                    style={{
                        color:"#1E1E1E",
                        fontFamily:"Montserrat",
                        fontWeight:"700"
                    }}
                >
                    {
                        subWeightsOpen ? 
                        `${subWeightsOpen} Details`
                        :
                        "Score Weightage"
                    }
                </div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="#1E1E1E" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    scrollbarGutter :"stable both-edges"
                }}
            >
                {
                !subWeightsOpen &&
                <div className="pie-chart-container">
                    <PieChart 
                        colors={["#EC6B56", "#FFC154", "#47B39C", "#1F75FE", "#00008B"]}
                        series={[{
                                data : weights,
                                innerRadius: 100,
                                paddingAngle : 1,
                                cornerRadius : 5,
                                cx : isMobile ? 110 : 150,
                                cy : 150,
                            }
                        ]}
                        width={isMobile ? 250 : 500}
                        height={isMobile ? 450 : 300}
                        slotProps={isMobile ? {
                            legend:{
                                position : {
                                    horizontal : "middle",
                                    vertical : "bottom"
                                }
                            }
                        } : {}}
                    />
                </div>
                }
                <div className="slider-container">
                    {
                        (
                            subWeightsOpen ?
                            priorities.find(item => item.name === subWeightsOpen).childrens || []
                            :
                            priorities
                        ).map((item, idx)=>(
                            <div key={idx}>
                                <div className="slider-item">
                                    <div>
                                        <label htmlFor={item.name}><b>{item.name}</b></label>
                                        <div>Priority : {item.priority}</div>
                                    </div>
                                    {
                                        item.childrens &&
                                        <IconButton onClick={()=>handleOpenSubWeights(item)}>
                                            <Settings />
                                        </IconButton>
                                    }
                                    {
                                        subWeightsOpen &&
                                        <IconButton onClick={()=>handleRemoveKeyword(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </div>
                                <Slider name={item.name} id={item.name} value={item.priority} onChange={handleOnChange} 
                                    min={0}
                                    max={10}
                                    step={1}
                                    shiftStep={2}
                                    marks
                                />
                            </div>
                        ))
                    }
                </div>

                {
                    subWeightsOpen &&
                    <div className="new-keyword-input" onSubmit={handleAddNewKeyword}>
                        <CustomTextField
                            name="newKeyWord"
                            sx={{
                                height:"2.8rem"
                            }}
                            value={newSubKeyword}
                            onChange={(event)=>setNewSubKeyword(event.target.value)}
                            onKeyDown={(event)=>{
                                if(event.key === "Enter"){
                                    handleAddNewKeyword();
                                }
                            }}
                            placeholder="Enter Another Keyword"
                        />
                        <IconButton 
                            sx={{
                                background: "#EA723C",
                                borderRadius: "6px",
                                height: "2.5rem",
                                width: "2.5rem",
                                marginLeft: "1rem",
                                "&:hover": {
                                    background: "#EA723CD3"
                                }
                            }}
                            onClick={handleAddNewKeyword}
                        >
                            <PlusIcon />
                        </IconButton>
                    </div>
                }

                <div className="action-btns">
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: "none",
                            fontWeight: 700,
                            margin: "1rem",
                            padding: "0.5rem 2.5rem"
                        }}
                        onClick={()=>{
                            if(subWeightsOpen){
                                return handleResetSubKeywords();
                            }

                            setIsUpdating(true);
                            return onApplyWeights(document._id, priorities);
                        }}
                        disabled={isUpdating}
                    >
                        {subWeightsOpen ? "Reset" : "Apply Weights" } 
                    </Button>
                </div>

            </DialogContent>
        </Dialog>
    )
}

export default AdvancedWeightgeScoreDialog;