import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, createTheme, Divider, Paper, Tab, Tabs, ThemeProvider } from "@mui/material";
import "./help-and-support.css";
import { useMemo, useState } from "react";
import { FillButton, LoadingIndicator } from "@components/index";
import { fetchTickets, raiseTicket } from "@services/ticketsService";
import { toast } from "react-toastify";
import { CustomTextField } from "@components/CustomMUIInputs";
import { AttachIcon, PlusIcon, SearchIcon } from "@icons/ActionsIcons";
import { Link } from "react-router-dom";
import useResponsiveView from "@hooks/useResponsiveView";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import RaiseATicketForm from "./RaiseATicketForm";

const customTabsTheme = createTheme({
    palette : {
        primary : {
            main : "#EA723C",
        },
    },
    components : {
        MuiTab : {
            styleOverrides : {
                root : {
                    fontSize:"0.9rem",
                    textTransform:"none",
                    transition:"color ease-in-out 300ms",
                    padding : "0.25rem",
                    minHeight:"1.25rem",
                    "&:hover" : {
                        color: "#EA723C"
                    }
                }
            }
        },
        MuiTabs : {
            styleOverrides : {
                root : {
                    minHeight:"2rem",
                    borderBottom:"1px solid #00000020"
                }
            }
        }
    }
})

const ticketStatusColorMap = {
    "pending" : {
        "color" : "#EB9900",
        "background" : "#FFEBC7"
    },
    "acknowledged" : {
        "color" : "#05A8FF",
        "background" : "#D8F8FF"
    },
    "resolved" : {
        "color" : "#14B908",
        "background" : "#BFFFC2"
    }
}

const FAQsData = [
    {
        question: "What is a Scan2Hire and how does it work?",
        answer:
          "A Scan2Hire is a tool or software that automatically extracts key details like contact info, experience, and skills from resumes using AI and ML, organizing the data for quick review and saving you time.",
      },
      {
        question: "What formats does the Scan2Hire support?",
        answer:
          "Our Scan2Hire supports PDF, DOC, DOCX, and TXT formats, ensuring compatibility with most resume submissions.",
      },
      {
        question: "How accurate is the resume parsing technology?",
        answer:
          "Our parser achieves over 95% accuracy in data extraction, minimizing errors and ensuring reliable candidate information.",
      },
      {
        question: "Can the Scan2Hire integrate with my existing systems?",
        answer:
          "Yes, our Scan2Hire seamlessly integrates with popular Applicant Tracking Systems (ATS) and HR software, fitting smoothly into your existing workflow.",
      },
      {
        question: "How does the Scan2Hire improve the hiring process?",
        answer:
          "By automating resume screening, our parser speeds up your recruitment process, enhances accuracy, and helps you quickly identify top candidates.",
      },
      {
        question: "Is my data secure with your Scan2Hire?",
        answer:
          "Absolutely. We prioritize data security with robust measures to protect your sensitive information and ensure confidentiality.",
      },
      {
        question: "How can I get started with the Scan2Hire?",
        answer:
          "Just sign up for a free trial on our website, and we’ll guide you through the quick and easy setup process.",
      },  
]

const FAQsItem = ({faq})=>{
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`faqs-item`} onClick={()=>setOpen(prev=>!prev)}>
            <div className="faqs-question">
                {faq.question}
                <h3 className="faqs-button">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h3>
            </div>
            <Collapse in={open} >
                <div className="faqs-bar"></div>
                <div className="faqs-answer">{faq.answer}</div>
            </Collapse>
        </div>
    )
}

const HelpAndSupport = ()=>{
    const [statusFilter, setStatusFilter] = useState("");
    const [searchFilterText, setSearchFilterText] = useState("");
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const isMobile = useResponsiveView();

    const queryKeys = useMemo(()=>['tickets'], []);
    const queryClient = useQueryClient();
    const { data:tickets=[], isLoading } = useQuery({
        queryKey : queryKeys,
        queryFn : fetchTickets,
    })
    

    const handleTicketSubmit = async (data)=>{
        const formData = new FormData();
        for(let key in data){
            if(key === "attachments"){
                data[key].forEach(file=>formData.append("attachments", file));
                continue;
            }

            formData.append(key, data[key]);
        }
        try{
            const data = await raiseTicket(formData);
            queryClient.setQueryData(queryKeys, (prev)=>([...prev, data]));
            toast.success("Ticket submitted", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            setOpenTicketDialog(false);
        }
    }

    return (
        <div className={isMobile ? "home-container" : "help-and-support-container"}>
            <RaiseATicketForm 
                open={openTicketDialog}
                onClose={()=>setOpenTicketDialog(false)}
                onSubmit={handleTicketSubmit}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:"100%",
                    padding:"0rem 1rem",
                    borderRadius:"20px",
                    display:"flex",
                    flexDirection: isMobile ? "column" : null,
                    justifyContent:"space-between",
                    overflow:"hidden",
                    position:"relative",
                }}
            >
                <div className="help-and-support-panels left">
                    <div className="has-left-title">Find Answeres Quickly</div>
                    <CustomTextField 
                        sx={{
                            height:"2.8rem"
                        }}
                        placeholder={"Search..."}
                        EndIcon={SearchIcon}
                        value={searchFilterText}
                        onChange={(event)=>setSearchFilterText(event.target.value)}
                    />
                    <h4 className="has-left-faqs-title">FAQs</h4>
                    <div className="has-left-faqs">
                        {
                            FAQsData.map((item, idx)=>(
                                item.question.toLowerCase().includes(searchFilterText.toLowerCase())
                                ||
                                item.answer.toLowerCase().includes(searchFilterText.toLowerCase())
                                ?
                                <FAQsItem key={idx} faq={item} />
                                : null
                            ))
                        }
                    </div>
                    <div className="has-left-contact-us-card"
                        style={{
                            backgroundImage:"url('contact-us-card-bg.png')"
                        }}
                    >
                        <h3>Do you still need our help?</h3>
                        <div>Send your request via Email</div>
                        <Link to={"mailto:connect@scan2hire.com"}>
                            Contact Us
                        </Link>
                    </div>
                </div>
                <div className="help-and-support-panels right">
                    <ThemeProvider theme={customTabsTheme}>
                        <Tabs 
                            variant="scrollable"
                            value={statusFilter} onChange={(event, newVal) => setStatusFilter(newVal)}
                        >
                            <Tab value={""} label="All Tickets" />
                            <Tab value={"pending"} label="Pending" />
                            <Tab value={"acknowledged"} label="Acknowledged" />
                            <Tab value={"resolved"} label="Resolved" />
                        </Tabs>
                    </ThemeProvider>
                    <div className="has-right-btn-container">
                        <FillButton
                            key={"ticketAddBtn"}
                            value={"New Ticket"}
                            className={"add-ticket-btn"}
                            startIcon={<PlusIcon className="addticket-svg-icon" />}
                            onClick={()=>setOpenTicketDialog(true)}
                        />
                    </div>
                    {
                        isLoading ? <LoadingIndicator type="text" /> : 
                        <div className="has-right-tickets-container">
                            {
                                tickets.filter(ticket=>((statusFilter === "" || ticket.status === statusFilter))).length === 0 ?
                                <div className="no-tickets-msg">
                                    No Tickets
                                </div>
                                :
                                tickets.map((ticket, idx)=>(
                                    (statusFilter === "" || ticket.status === statusFilter) ? 
                                    <div key={idx} className="ticket-card">
                                        <div className="ticket-header">
                                            <div className="ticket-number">Ticket Number : {ticket?.ticket_number}</div>
                                            <div 
                                                className="ticket-status"
                                                style={{
                                                    color: ticketStatusColorMap[ticket?.status]?.color || "gray",
                                                    background: ticketStatusColorMap[ticket?.status]?.background || "lightgray"
                                                }}
                                            >{ticket?.status}</div>
                                        </div>
                                        <Divider className="ticket-divider" />
                                        <div className="ticket-content">
                                            <div className="subject">{ticket?.subject}</div>
                                            <div className="description">{ticket?.description}</div>
                                        </div>
                                        <div className="ticket-footer">
                                            <div className="datetime">
                                                {dayjs(ticket?.created_at).format("DD MMM, YYYY | hh:mm A")}
                                            </div>
                                            <div className="attachments">
                                                <AttachIcon /> {ticket?.attachments}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                ))
                            }
                        </div>
                    }
                </div>
            </Paper>
        </div>
    )
}

export default HelpAndSupport;