import { CustomTextField } from "@components/CustomMUIInputs";
import { FileDropInput } from "@components";
import { CrossCircleIcon, SuggestionIcon } from "@icons/DialogsIcons";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import React, { useState } from "react";
import styles from "./uploadJdDialog.module.css";
import { generateJD } from "@services/documentsService";
import { LoadingButton } from "@mui/lab";
import { generatePassword } from "@utils/securityUtils";

const UploadJDDialog = ({open, onClose, onSubmit})=>{
    const [mode, setMode] = useState("file");
    
    const [uploads, setUploads] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [generatedJD, setGeneratedJD] = useState("");
    const [errors, setErrors] = useState({});
    
    const handleGenerateJD = async (event)=>{
        setIsGenerating(true);
        setPrompt("Generating...");
        const jd = await generateJD(prompt);
        setIsGenerating(false);
        setPrompt("")
        setGeneratedJD(jd);
        setErrors({});
    }

    const handleClose = ()=>{
        onClose();
        setGeneratedJD("");
        setIsGenerating(false);
        setPrompt("");
        setUploads([]);
        setErrors({});
    }

    const handleSubmit = (event)=>{
        event.preventDefault();
        const formData = new FormData(event.target);
        let files = [];
        switch(mode){
            case 'file':
                if(uploads.length === 0){
                    setErrors({"file" : "No File Selected"});
                    return;
                }
                files = uploads;
                break;
            case 'text':
                const jdText = formData.get("job_description");
                if(jdText === ""){
                    setErrors({"text" : "Empty Job Description"});
                    return;
                }
                const textBlob = new Blob([jdText], {type:"plain/text"});
                let textFile = new File([textBlob], `rename-me-${generatePassword(4)}.txt`, {type:"plain/text"});
                files = [textFile];
                break;
            case 'generate':
                if(generatedJD === "" || generatedJD === "Invalid Prompt"){
                    setErrors({"generate" : "Please generate a valid JD"});
                    return;
                }
                let generatedBlob = new Blob([generatedJD], {type:"plain/text"});
                let generatedFile = new File([generatedBlob], `rename-me-${generatePassword(4)}.txt`, {type:"plain/text"});
                files = [generatedFile];
                break;
            default:
                return;
        }
        onSubmit({
            files
        });
        handleClose();
    }

    const renderInput = (mode)=>{
        switch(mode){
            case 'file':
                return <FileDropInput id={"jd-upload"} defaultValue={uploads} onChange={setUploads} error={!!errors.file} helperText={errors.file}/>;
            case 'text':
                return <CustomTextField name={"job_description"} sx={{borderStyle:"dashed"}}  placeholder={"Paste Job Description Here"} multiline={true} minRows={6} maxRows={6} error={!!errors.text} helperText={errors.text}/>;
            case 'generate':
                return <>
                    <div
                        className={styles.generate}
                    >
                        <CustomTextField value={prompt} sx={{borderStyle:"dashed"}} disabled={isGenerating} onKeyDown={(e)=>{
                            if(e.key !== "Enter") return;
                            handleGenerateJD(e);
                        }} onChange={(e)=>setPrompt(e.target.value)} placeholder={"Prompt"} style={{width:"calc(100% + 2rem)"}} error={!!errors.generate} helperText={errors.generate} />
                        <Tooltip title={"Generate"} arrow>
                            <span>
                                <LoadingButton style={{paddingRight:"0rem", marginRight:"0", minWidth:"0rem"}} loading={isGenerating} onClick={handleGenerateJD}>
                                    <SuggestionIcon className={styles.generateBtn} color={isGenerating ? "transparent" : "black"} />
                                </LoadingButton>
                            </span>
                        </Tooltip>
                    </div>
                    <Collapse in={!isGenerating && generatedJD !== ""} mountOnEnter unmountOnExit>
                        <CustomTextField value={generatedJD} sx={{borderStyle:"dashed"}} onChange={(event)=>setGeneratedJD(event.target.value)} placeholder={"Preview - Edit after generating the JD"} multiline={true} minRows={6} maxRows={6} />
                    </Collapse>
                </>;
            default:
                return null;
        }
    }
    const renderLink = (mode)=>{
        switch(mode){
            case 'file':
                return "Upload the File(s)";
            case 'text':
                return "Paste a Job Description";
            case 'generate':
                return "Generate a Job Description"
            default:
                return null;
        }
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:handleSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>Add Job Description</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    position:"relative",
                }}
            >
                {['file', 'text', 'generate'].map((item, idx) => (
                    <React.Fragment key={idx}>
                        {
                            idx === 0 ? null :
                            <DialogContentText>or</DialogContentText>
                        }
                        <Collapse in={mode === item} style={{width:"100%"}} mountOnEnter unmountOnExit timeout={300}>
                            {renderInput(item)}
                        </Collapse>
                        {mode !== item && (
                            <>
                                <DialogContentText className={styles.link} onClick={() => setMode(item)}>
                                    {renderLink(item)}
                                </DialogContentText>
                            </>
                        )}
                    </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                <Button
                    disabled={isGenerating}
                    type={"submit"}
                    variant="contained"
                    style={{
                        background:"#EA723C",
                        textTransform:"none",
                        width:"100%",
                        height:"2.8rem",
                        margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                        borderRadius:"6px",
                        fontWeight:"600"
                    }} 
                >Upload</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadJDDialog;