import { CustomTextField } from "@components/CustomMUIInputs";
import { DeleteIcon, PlusIcon } from "@icons/ActionsIcons";
import { EditOutlined } from "@mui/icons-material";
import { Button, Collapse, IconButton } from "@mui/material";
import { useMemo, useState } from "react";
import "./dynamic-input-group.css";
import { generatePassword } from "@utils/securityUtils";

const DynamicInputGroup = ({id, label, fields=[], value=[], itemRenderer=null, onChange=()=>{}}) => {
    const [formFields, setFormFields] = useState(()=>{
        return fields.reduce((acc, field)=>{
            acc[field.name] = '';
            return acc;
        }, {})
    });
    const [action, setAction] = useState("");
    
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormFields(prev=>({...prev, [name]:value}));
    }

    const handleSubmit = (e)=>{
        if(action === "add"){
            const updatedValue = [...value, {_id : generatePassword(), ...formFields}];
            setFormFields({});
            onChange(updatedValue);
        }else if(action === "edit"){
            const updatedValue = [...value].map(item => (
                item._id === formFields._id ? {...item, ...formFields} : item
            ));
            setFormFields({});
            onChange(updatedValue);
            setAction("add");
        }
    }

    const handleDelete = (deletedItem) => {
        onChange(prev=>prev.filter(item => item !== deletedItem))
    }

    const isFormValid = useMemo(()=>{
        return fields.every(
            field => !field.required || formFields[field.name]
        )
    }, [fields, formFields]);

    return (
        <>
        <div className="dig-header-container" key={id}>
            <h3 className="dig-header">
                {label} <span>({value.length})</span>
            </h3>
            <IconButton className="dig-add-more" onClick={()=>setAction("add")} >
                <PlusIcon className="dig-add-more-icon" color="black" />
            </IconButton>    
        </div>
        <div className="dig-details">
            {
                value.map((valueItem, idx)=>(
                    <div key={idx} className="dig-details-item">
                        <div className="left">
                            {
                                itemRenderer ? itemRenderer(valueItem) :
                                fields.map((item, idx)=>(
                                    <div key={idx}>{valueItem?.[item.name]}</div>
                                ))
                            }
                        </div>
                        <div className="right">
                            <IconButton onClick={()=>{
                                setAction("edit");
                                setFormFields(valueItem);
                            }}>
                                <EditOutlined />
                            </IconButton>
                            <IconButton onClick={()=>handleDelete(valueItem)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                ))
            }
        </div>
        <Collapse id={"dig-form" + id} in={action === "add" || action === "edit"}>
            <div className="dig-section">
                {
                    fields.map((fieldItem, idx)=>(
                        <CustomTextField 
                            key={idx} 
                            id={fieldItem.id || ("dig" + label + fieldItem.name)} 
                            {...fieldItem} 
                            value={formFields[fieldItem.name] || ""}
                            onChange={handleFieldChange}
                        />
                    ))
                }
            </div>
            <center>
                <Button
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    sx={{
                        width:"fit-content",
                        textTransform:"capitalize",
                        borderRadius:"6px",
                        fontWeight:"500",
                        margin:"0.5rem",
                        padding:"0.3rem 0.5rem",
                        boxSizing:"border-box",
                        color:"white",
                        background:"#EA723C",
                        "&:hover" : {
                            color:isFormValid ? "white" : "darkgray",
                            background:isFormValid ? "#EA723C" : "lightgray"
                        },
                        "&.Mui-disabled" : {
                            color:"darkgray",
                            background:"lightgray"
                        }
                    }}
                    disabled={!isFormValid}
                >{action} Details</Button>
            </center>
        </Collapse>
        </>
    )
}

export default DynamicInputGroup;