import "./rank-and-analyze.css";
import { useCallback, useEffect, useState } from "react";
import { IconButton, FormControlLabel, Radio, RadioGroup, Fade, Button, Paper, Chip, Collapse } from "@mui/material";

import useResponsiveView from "@hooks/useResponsiveView";
import { AutorenewOutlined, Leaderboard, Settings } from "@mui/icons-material";
import { CustomAutoComplete, CustomTextField } from "@components/CustomMUIInputs";
import { FilenameRenderer, PreviewRenderer, StatusRenderer } from "@renderers/documents-renderers";
import { AnalyzeRenderer, ScoreRenderer } from "@renderers/rank-and-analyze-renderers";
import { AdvancedWeightageScoreDialog, CustomGrid, DocumentPreview, LoadingIndicator } from "@components/index";
import { useDocuments } from "@contexts/DocumentsContext";
import { getAdvancedWeightageScores, getSimilarityScore } from "@services/documentsService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CrossIcon, FilterIcon } from "@icons/DialogsIcons";
import RNAFilterDrawer from "@components/FilterDrawer/RNAFilterDrawer";
import ScoreDistributionDialog from "@components/ScoreDistributionDialog/ScoreDistributionDialog";
import { SearchIcon } from "@icons/ActionsIcons";
import useSearchParams from "@hooks/useSearchParams";

const RankAndAnalyze = () => {
    const { documents, isLoading:isDocumentsLoading, isStale, updateDocumentStatus, mutateUpdateDocument, syncDocuments } = useDocuments();
    const navigate = useNavigate();
    const location = useLocation();

    const updateSearchParams = useSearchParams();


    const [rowData, setRowData] = useState([]);
    const [filter, setFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            return filterParam;
        }
        return 'job-descriptions'
    });
    const [selectedDocument, setSelectedDocument] = useState("");
    const [previewDocument, setPreviewDocument] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);
    const [openAWSDialog, setOpenAWSDialog] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSDDialog, setOpenSDDialog] = useState(false);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchKeywords, setSearchKeywords] = useState(()=>{
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get("keywords")?.split("_") || [];
    });
    const [scoreFilter, setScoreFilter] = useState(()=>{
        const searchParams = new URLSearchParams(location.search);
        return {min: parseInt(searchParams.get("min") || 0), max: parseInt(searchParams.get("max") || 100)}
    });
    const [isLoading, setIsLoading] = useState(false);

    const isExternalFilterPresent = useCallback(()=>{
        return !!searchKeywords.length || scoreFilter.min > 0 || scoreFilter.max < 100;
    }, [searchKeywords, scoreFilter]);
    
    const doesExternalFilterPass = useCallback((node)=>{
        for(let keyword of searchKeywords){
            if(!(
                    node.data?.cleaned_text.trim().toLowerCase()
                    + node.data?.filename.trim().toLowerCase()
                )
                .includes(keyword.trim().toLowerCase())){
                return false;
            }
        }

        if((scoreFilter.min > 0) && (100 * node.data.score < scoreFilter.min)){
            return false;
        }
        if((scoreFilter.max < 100) && (100 * node.data.score > scoreFilter.max)){
            return false;
        }

        return true
    }, [searchKeywords, scoreFilter]);
    
    const isMobile = useResponsiveView();

    useEffect(()=>{
        if(isDocumentsLoading) return;
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            setFilter(filterParam);
        } 

        const documentParam = queryParams.get("id");
        const selectedParam = documents.find(item => (item._id === documentParam) && (item.document_type === filterParam));
        if(documentParam && selectedParam){
            handleChangeDocument({}, selectedParam);
        }
    }, [isDocumentsLoading])

    const handleChangeDocument = async (event, newVal) => {
        if(!newVal){
            setSelectedDocument("");
            setRowData([]);
            return;
        }
        setSelectedDocument(newVal);
        navigate(`?filter=${filter}&id=${newVal._id}`, {replace: true})
        if(!newVal) return;

        setIsLoading(true);
        try{
            const data = await getAdvancedWeightageScores(newVal?._id);
            setRowData(data.map(item=>{
                const otherDocument = documents.find(innerItem=>innerItem._id === item._id);
                if(!otherDocument){
                    return otherDocument
                }
    
                return {
                    _id : otherDocument._id,
                    score : item.score,
                    analyze_details: item.analyze_details,
                    status : otherDocument.status,
                    filename : otherDocument.filename,
                    document_type : otherDocument.document_type,
                    target : otherDocument.target,
                    cleaned_text: otherDocument.cleaned_text
                }
            }).filter(item=>!!item)) 
        }catch(error){
            toast.error(error.message);
        }finally{
            setIsLoading(false);
        }
    }

    const handleFilterChange = (event, newVal)=>{
        setFilter(newVal);
        navigate(`?filter=${newVal}`, {replace: true})
        setSelectedDocument(null);
        setRowData([]);
    }

    const handleStatusUpdate = async (documentId, actionItem) => {
        try {
            await updateDocumentStatus(documentId, actionItem);
            setRowData(prev=>prev.map(doc => 
                doc._id === documentId ? {...doc, status : actionItem} : doc
            ))
            setRowData(prev=>prev.filter(doc => doc.status !== "archived"))
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            // nothing to do currently
        }
    }

    useEffect(()=>{
        updateSearchParams({
            'min': scoreFilter.min > 0 ? scoreFilter.min : "",
            'max': scoreFilter.max < 100 ? scoreFilter.max : "",
            "keywords" : searchKeywords.join("_")
        });
    }, [scoreFilter, searchKeywords])

    const columnDefs = [
        { headerName: 'Document', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:3,
            minWidth:250,
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",
            },
            cellClass:"name-cell",
            headerClass:"not-centered-header-cell",
            cellRenderer:FilenameRenderer
        },
        {
            headerName:"Status", flex:3, cellClass:"centered-cell", field:"status", cellRenderer:StatusRenderer,
            cellRendererParams:{
                onUpdate:handleStatusUpdate
            }
        },
        { headerName: 'Score', field:'score', flex:1, sort:"desc", sortable:"true",  cellClass:"centered-cell", cellRenderer:ScoreRenderer },
        { headerName: filter === "resumes" ? "View Job" : 'Preview', flex:1, cellClass:"centered-cell", cellRenderer:PreviewRenderer, 
            cellRendererParams:{
                onPreview:(document)=>{
                    setPreviewDocument(documents.find(item=>item._id === document._id));
                    setOpenPreview(true);
        }}},
        { headerName: 'Analyze', flex:1, colId:"analyze", cellClass:"centered-cell", cellRenderer:AnalyzeRenderer }
    ]

    // const getRowStyle = useCallback((params) => {
    //     if (selectedDocument.document_type === "job-descriptions" && params.data.target !== selectedDocument?._id) {
    //       return { backgroundColor: 'rgba(225,254,224, 0.35)' }; 
    //     }
    //     return null;
    // }, [selectedDocument]);

    const handleApplyWeights = async (document_id, weights) => {
        try {
            await mutateUpdateDocument({data:[document_id, {weights}]});
            toast.success("Weights updated");
            handleChangeDocument({}, selectedDocument);
        }catch(error){
            toast.error(error.message);
        }finally{
            setOpenAWSDialog(false);
        }
    }

    const handleUpdateDocuments = ()=>{
        syncDocuments();
        setSelectedDocument("");
        setRowData([]);
    }

    return (
        <div className={isMobile ? "home-container" : "rank-and-analyze-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <DocumentPreview 
                open={openPreview}
                document={previewDocument}
                onClose={()=>{
                    setOpenPreview(false);
                }}
            />

            <AdvancedWeightageScoreDialog
                open={openAWSDialog}
                document={selectedDocument}
                onApplyWeights={handleApplyWeights}
                onClose={()=>{
                    setOpenAWSDialog(false);
                }}
            />

            <ScoreDistributionDialog 
                open={openSDDialog}
                scores={rowData}
                onFilterApply={(filter)=>{
                    setScoreFilter(filter);
                    setOpenSDDialog(false);
                }}
                onClose={()=>{
                    setOpenSDDialog(false);
                }}
            />

            <RNAFilterDrawer 
                open={openDrawer}
                defaultScoreFilter={[scoreFilter.min, scoreFilter.max]}
                onClose={()=>setOpenDrawer(false)}
                onApplyFilters={(filters)=>{
                    setScoreFilter(filters.scoreFilter);
                    setOpenDrawer(false);
                }}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:"100%",
                    padding:"0rem 1rem",
                    height:"100%",
                    borderRadius:"18px",
                    display:"flex",
                    flexDirection:"column",
                }}
            >
                <div className="rank-and-analyze-header">
                    <div className="rank-and-analyze-header-top">
                        <RadioGroup
                            row
                            style={{
                                minWidth : "fit-content"
                            }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="job-descriptions"
                            name="radio-buttons-group"
                            id="radio-buttons-group"
                            value={filter}
                            onChange={handleFilterChange}
                        >
                            <FormControlLabel value="job-descriptions"
                            sx={{
                                background:filter === 'job-descriptions' ? "#EA723C" : undefined,
                                borderRadius:"25px",
                                color:filter === 'job-descriptions' ? "white" : undefined,
                                padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                                height : "2.5rem"
                            }}
                            control={<Radio 
                                sx={{
                                    '&.Mui-checked': {
                                        color: "white",
                                    },
                                }}  
                            />} label="Job Descriptions" />
                            <FormControlLabel value="resumes"
                            sx={{
                                background:filter === 'resumes' ? '#EA723C' : undefined,
                                borderRadius:"25px",
                                color:filter === 'resumes' ? "white" : undefined,
                                padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                                height : "2.5rem"
                            }}
                            control={<Radio 
                            sx={{
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}
                            />} label="Resumes" />
                        </RadioGroup>


                        <CustomAutoComplete
                            disablePortal
                            id="combo-box-demo"
                            options={documents?.filter(x=>x.document_type === filter) || []}
                            getOptionKey={(document)=>document._id}
                            getOptionLabel={(document)=>document ? document.filename : ''}
                            sx={{ 
                                width: "100%", padding : "0rem 0.2rem", boxSizing : "border-box",
                                "& .MuiInputBase-root" : {
                                    height : "2.8rem",
                                }
                            }}
                            defaultValue={null}
                            value={selectedDocument || null}
                            placeholder="Select Document"
                            onChange={handleChangeDocument}
                        />

                        
                        <Fade in={isStale}>
                            <IconButton
                                style={isMobile ? {
                                    position:"absolute",
                                    right:"calc(2% + 1rem)"
                                } : {margin : "0rem 0.5rem"}}
                                onClick={handleUpdateDocuments}
                            >
                                <AutorenewOutlined style={{
                                    color:"#EA723C"
                                }} />
                            </IconButton>
                        </Fade>
                    </div>

                    <div className="rna-header-bar">
                        <CustomTextField
                            sx={{
                                height:"2.8rem"
                            }}
                            placeholder={"Search..."}
                            EndIcon={SearchIcon}
                            value={searchKeyword}
                            onChange={(event)=>setSearchKeyword(event.target.value)}
                            onKeyDown={(event)=>{
                                if(event.key === "Enter"){
                                    setSearchKeywords(prev=>[...prev, ...searchKeyword.split("_")]);
                                    setSearchKeyword("");
                                }
                            }}
                        />
                        
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius:"8px",
                                textTransform:"none",
                                height: "2.8rem",
                                padding: "0.5rem 1rem",
                                margin: "0rem 1rem",
                                minWidth: "fit-content"
                            }}
                            onClick={()=>setOpenSDDialog(true)}
                            disabled={!selectedDocument}
                            endIcon={<Leaderboard />}
                        >Score Distribution</Button>
                        
                        <IconButton disabled={!selectedDocument} onClick={()=>setOpenAWSDialog(true)}
                            sx={{
                                marginRight : "0.5rem"
                            }}    
                        >
                            <Settings fontSize="medium" />
                        </IconButton>
                        <IconButton
                            onClick={()=>setOpenDrawer(true)}
                        >
                            <FilterIcon color={"#1E1E1E"} height="2.5rem" width="2.5rem" />
                        </IconButton>
                    </div>
                </div>

                <Collapse in={!!searchKeywords.length || (scoreFilter.min > 0) || (scoreFilter.max < 100)} mountOnEnter unmountOnExit>
                    <div className="filters-chips">
                        <IconButton 
                            style={{
                                height: "100%",
                                margin: "0.25rem 0.5rem"
                            }}
                            onClick={()=>{
                                setScoreFilter({min: 0, max: 100})
                                setSearchKeywords([])
                            }}
                        >
                            <CrossIcon color="gray" />
                        </IconButton>
                        {
                            (scoreFilter.min > 0) &&
                            <Chip
                                sx={{
                                    fontSize:"0.8rem",
                                    height:"1.75rem", 
                                    padding:"0rem 0.3rem", 
                                    margin:"0.125rem 0.25rem",
                                    "& .MuiSvgIcon-root" : {
                                        height:"65%"
                                    }
                                }}    
                                label={`min: ${scoreFilter.min}`}
                                onDelete={()=>{
                                    setScoreFilter(prev=>({...prev, min: 0}));
                                }}
                            />
                        }
                        {
                            (scoreFilter.max < 100) &&
                            <Chip
                                sx={{
                                    fontSize:"0.8rem",
                                    height:"1.75rem", 
                                    padding:"0rem 0.3rem", 
                                    margin:"0.125rem 0.25rem",
                                    "& .MuiSvgIcon-root" : {
                                        height:"65%"
                                    }
                                }}    
                                label={`max: ${scoreFilter.max}`}
                                onDelete={()=>{
                                    setScoreFilter(prev=>({...prev, max: 100}));
                                }}
                            />
                        }
                        {
                            searchKeywords.map((item, idx)=>(
                                <Chip
                                    key={idx}
                                    sx={{
                                        fontSize:"0.8rem",
                                        height:"1.75rem", 
                                        padding:"0rem 0.3rem", 
                                        margin:"0.125rem 0.25rem",
                                        "& .MuiSvgIcon-root" : {
                                            height:"65%"
                                        }
                                    }}    
                                    label={item}
                                    onDelete={()=>{
                                        setSearchKeywords(prev => prev.filter(keyword => keyword !== item));
                                    }}
                                />
                            ))
                        }
                    </div>
                </Collapse>

                <CustomGrid 
                    gridId={"rank-and-analyze-grid"}
                    rowData={rowData}    
                    columnDefs={columnDefs}
                    containerStyle={{ mobile:{
                        width: 'calc(2rem + 100%)', height:"100%", marginLeft:"-1rem", marginTop:"0rem"
                    }}}
                    loading={isLoading}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    // getRowStyle={getRowStyle}
                />
            </Paper>
        </div>
    )
}

export default RankAndAnalyze;