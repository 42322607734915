import useResponsiveView from "@hooks/useResponsiveView";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { Scale, Score, Settings } from "@mui/icons-material";
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Slider } from "@mui/material";
import { BarChart, PieChart, ResponsiveChartContainer } from "@mui/x-charts";
import { useEffect, useState } from "react";

const ScoreDistributionDialog = ({open, scores, onFilterApply=()=>{}, onClose=()=>{}})=>{
    
    const isMobile = useResponsiveView();
    const [plotData, setPlotData] = useState([]);
    const handleClose = ()=>{
        onClose();
    }

    const handleItemClick = (event, item) => {
        const {min, max} = plotData?.[item.dataIndex];
        onFilterApply({min, max})
    }

    useEffect(()=>{
        if(!scores) return;
        const [minItem, maxItem, binSize] = [0, 100, 10]

        let bins = [];
        for(let i=minItem; i<maxItem; i+=binSize){
            bins.push({min:i, max:i+binSize, count:0})
        }
        scores.forEach(item=>{
            const binIndex = Math.floor((item.score*100-minItem)/binSize);
            if(binIndex >=0 && binIndex <= bins.length){
                bins[binIndex].count += 1;
            }
        })

        setPlotData(bins);
    }, [scores])

    return (
        <Dialog fullWidth open={open || false} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:isMobile ? "0" : "1rem", height:"80%", maxHeight:"550px", background:"white"},
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    position:"relative"
                }}
            >
                <div
                    style={{
                        color:"#1E1E1E",
                        fontFamily:"Montserrat",
                        fontWeight:"700"
                    }}
                >
                    Score Distribution
                </div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="#1E1E1E" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    scrollbarGutter :"stable both-edges"
                }}
            >
                <div style={{width:"100%", height:"500px"}}>
                    <BarChart
                        dataset={plotData}
                        borderRadius={6}
                        // loading={loading}
                        grid={{horizontal:true, vertical:false}}
                        xAxis={[
                            {
                                scaleType: 'band',
                                dataKey: "max",
                                label: 'Score',
                            },
                        ]}
                        yAxis={[    
                            {
                                label: 'Number of Candidates',    
                                tickLabelPlacement : "tick"
                            },
                        ]}
                        series={[
                            {
                                label:"Count",
                                dataKey : "count",
                                color:"#EA723C6D",
                            }
                        ]}
                        slotProps={{
                            legend: {
                                hidden : true
                            },
                            axisLabel:{
                                style:{
                                    fontSize:"0.95rem",
                                    fontWeight:"500",
                                    fontFamily:"inherit",
                                }
                            },
                            axisTickLabel: {
                                style:{
                                    fontSize:"0.75rem",
                                    fontWeight:"400",
                                    fontFamily:"inherit"
                                }
                            },
                        }}
                        onItemClick={handleItemClick}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ScoreDistributionDialog;